@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300&display=swap');



.paragrafoGeral{
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color:rgba(26, 26, 26, 0.829);
    margin-top: 30px;
}

.quem-somos-img{
    width: 100%;
    margin-bottom: 20px;
}


.quemsomos__heading:before {
    position: absolute;
    content: '';
    border-bottom: 25px solid rgb(211, 223, 100);
    width: 29rem;
    display: block;
    margin: 0 auto;
    position: relative;
    left: 2.5rem;
    top: 5.7rem;
    z-index: -1;
}

.titleQuemSomos {
    font-size: 5em;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color:rgb(37, 37, 37);
  }
  
@media (max-width: 626px){
    .titleQuemSomos {
      font-size: 3em !important;
    }
    .quemsomos__heading:before{
        border-bottom: 15px solid rgb(211, 223, 100);
        width: 16.9rem;
        left: 1.3rem;
        top: 3.2rem;
    }
  }
  

  .mtopQuemSomos{
    margin-top: 30px;
  }

.margem{
    margin: 60px;
}

.mbottom{
    margin-bottom: 40px;
}

.icon-instagram{
    width: 20px;
}