@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300&display=swap');



.material-symbols-outlined {
    margin-left: 5px;
    position:relative;
    top:2px;
    color: rgb(53, 53, 53) !important;
    margin-right: 5px;
    font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' -25,
    'opsz' 40
  }

.denunciaLink{
    font-size: 32px;
    text-align: center;
    margin-top: 30px;
}

.denunciaLink a {
        position: relative;
        color: #000;
        text-decoration: none !important;
        background-color: rgb(211, 223, 100);
      }
      
.denunciaLink a::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 0%;
        height: 2px;
        background-color: #000000;
        opacity: 0.8;
        transition: width 0.2s ease-in-out;
      }
      
      /* Add the animation on hover */
.denunciaLink a:hover::after {
        width: 100%;
      }

.margin-container{
    margin-left: 10vmin;
    margin-right: 10vmin;
}

.linkText{
    color: rgb(53, 53, 53) !important;
}


.denuncias__heading:before {
  position: absolute;
  content: '';
  border-bottom: 25px solid rgb(211, 223, 100);
  width: 42rem;
  display: block;
  margin: 0 auto;
  position: relative;
  left: 2.5rem;
  top: 5.7rem;
  z-index: -1;
}

.titleDenuncias {
  font-size: 5em;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color:rgb(37, 37, 37);
}
.mtopDenuncias{
  margin-top: 30px;
}

.paragrafoGeral2{
  margin-top: 60px !important;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color:rgba(26, 26, 26, 0.829);
  font-size: 2em;
}


@media (max-width: 797px){
  .titleDenuncias {
    font-size: 3em !important;
  }
  .denuncias__heading:before{
      border-bottom: 15px solid rgb(211, 223, 100);
      width: 25.4rem;
      left: 1.3rem;
      top: 3.2rem;
  }
}

@media (max-width: 470px){
  .titleDenuncias {
    font-size: 2em !important;
  }
  .denuncias__heading:before{
      border-bottom: 15px solid rgb(211, 223, 100);
      width: 16.9rem;
      left: 1.1rem;
      top: 2.5rem;
  }
  .paragrafoGeral2{
    font-size: 1.5em;
  }
  .denunciaLink{
    margin-top: 60px;
  }
}


