
.container-paginas{
    max-width: 1310px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.container-title{
    margin: 40px;
    font-family: 'Roboto', sans-serif !important;
}


.white-square {
    width: 100%;
    height: 300px;
    background-color: rgba(255, 255, 255, 0.479);
  }


  .title-pessoa {
    font-size: 5em;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color:rgba(32, 32, 32, 0.829);
  }

  .foto-pessoa{

  }

  .pessoa-imagem{
    width: 100%;
  }

  .texto-pessoa{
    margin-bottom: 20px;
    color: #222222;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-align: justify;
  }
  

  .fonte{
    color: rgb(73, 73, 73);
    font-size: 0.8rem;
    text-align: center;
  }

  .fundo-fonte{
    background-color: rgba(255, 255, 255, 0.603);
  }


  .perfis__heading:before {
    position: absolute;
    content: '';
    border-bottom: 25px solid rgb(211, 223, 100);
    width: 30rem;
    display: block;
    margin: 0 auto;
    position: relative;
    left: 2.5rem;
    top: 5.7rem;
    z-index: -1;
}

.titlePerfis {
    font-size: 5em;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color:rgb(37, 37, 37);
  }

@media (max-width: 980px){
    .titlePerfis{
      font-size: 2.8em !important;
    }
    .perfis__heading:before{
        border-bottom: 15px solid rgb(211, 223, 100);
        width: 16.8rem;
        left: 1rem;
        top: 3.3rem;
    }
  }

  @media (max-width: 767px) {
    .mobile-margin-top {
      margin-top: 30px !important;
    }
    .title-pessoa{
      font-size: 3em !important;
    }
    .container-title{
      margin: 20px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .container-paginas{
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  @media (max-width: 592px) {
    .title-pessoa{
      font-size: 2em !important;
      text-decoration: underline;
    }
    .container-paginas{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  

  .mtop2{
    margin-top: 100px;
  }


.separator{
  border-bottom: solid 2px #4e4e4e;
  width: 20%;
}