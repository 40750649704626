.rodape{
    margin-top: auto;
    height: 30px;
    text-align: center;
    background-color: rgb(199, 199, 199);
    border-top: 1px solid rgb(173 171 171);
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
