
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300&display=swap');





body {
  background: rgb(223 223 223) !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  color: rgb(53, 53, 53) !important;
}

.title {
  font-size: 5em;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color:rgba(32, 32, 32, 0.829);
}

.subtitle {
  font-size: 2em;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color:rgba(8, 8, 8, 0.829);
  margin: 60px;
}

h3 {
  padding-top: 0.25em !important;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.vertical-timeline-element-subtitle{
  margin-top: 20px !important;
}

.vertical-timeline-element-title{
  color: #000000ce;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.date {
  color: rgb(201, 251, 255);
  font-weight: bolder !important;
}

#description {
  margin: 1.5em 0 2em 0;
  text-align: justify;
  color: #000000ce;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.workButton {
  background-color: #06d6a0;
}

.workButton:hover {
  background-color: #0ac593;
}

.schoolButton {
  background-color: #f9c74f;
}

.schoolButton:hover {
  background-color: #f3bc3c;
}

.spacer {
  margin-top: 20px; /* Adjust the desired amount of space */
}
.vertical-timeline-element-date{
  margin: 8px;
  top: -10px !important;
}


@media (max-width: 626px){
  .title{
    font-size: 3em !important;
  }
  .subtitle{
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 1170px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(0, 0, 0) !important;
    margin-top: 1.5em;
  }
  .subtitle{
    font-size: 24px !important;
  }
  .vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
      0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 1em 1em !important;
  }
}