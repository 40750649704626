.container-outer{
    margin-top: 30px !important;
}

.testeFundo{
    background-color: aliceblue !important;
}


.subSecaoTipoParlamentar{

}

.m20 {
    margin: 20px 0px; 
  }

.float-left{
    float: left !important;
  }

.nomeStyle{
    font-size: 14px;
}


@media (max-width: 1200px) {
    .nomeStyle{
        font-size: 12px;
    }
}


/* title underline */

.sobre__heading:before {
    position: absolute;
    content: '';
    border-bottom: 25px solid rgb(211, 223, 100);
    width: 47rem;
    display: block;
    margin: 0 auto;
    position: relative;
    left: 2.5rem;
    top: 5.7rem;
    z-index: -1;
}

.titleSobre {
    font-size: 5em;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color:rgb(37, 37, 37);
  }

@media (max-width: 991px){
    .titleSobre{
      font-size: 3em !important;
    }
    .sobre__heading:before{
        border-bottom: 15px solid rgb(211, 223, 100);
        width: 28.5rem;
        left: 0.8rem;
        top: 3.3rem;
    }
  }

  .mtop{
    margin-top: 30px;
  }

  @media (max-width: 470px){
    .titleSobre {
      font-size: 2em !important;
    }
    .sobre__heading:before{
        border-bottom: 15px solid rgb(211, 223, 100);
        width: 19.5rem;
        left: 0.7rem;
        top: 2.5rem;
    }
  }