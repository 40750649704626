@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300&display=swap');



.container-header {
    align-items: flex-end;
    justify-content: center;
    height: 25px;
    width: 100%;
    background-color: rgb(20, 20, 20);
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color:rgba(255, 255, 255, 0.829);
    position: fixed;
    z-index: 100;
    border-bottom: solid 1px rgb(65 65 65);
  }


.texto-barra{
    color: aliceblue;

    margin: 0;
}

.menu-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    
  }

li{
    list-style-type: none;
    padding-left: 1.2vw;
}

ul > li {
    display: inline-block;
}

#menu-item {
  margin-right: 20px; /* Adjust the spacing between items as needed */
}

#menu-item a {
  text-decoration: none;
  color: aliceblue;
  position: relative;
}

.menu-link {
    text-decoration: none;
    color: aliceblue;
    position: relative;
    z-index: 0;
  }
  
  .menu-link:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Adjust the color and opacity as needed */
    z-index: -1;
  }


  .headerSpace{
    margin-top: 25px;
  }


  /*MODAL SETTINGS*/

  .sidebar-modal{
    background-color: rgb(20, 20, 20);
    padding-bottom: 20px;
    padding-top: 10px;
    padding-left: 10px;
    position: fixed;
    margin-top: 50px;
    width: 100%;
    z-index: 100;
  }


  .sidebar-icon{
    width: 25px;
  }

  .sidebar-icon:hover{
    cursor: pointer;
  }


  @media (max-width: 980px){
    .container-header {
      height: 50px;
      position: fixed;
      z-index: 100;
      border-bottom: solid 1px rgb(65 65 65);
    }
    .sidebar-icon{
      width: 50px;
    }
    .menu-link {
      font-size: 20px !important;

    }
    .headerSpace{
      margin-top: 50px;
    }
  }