.titulo-bold {
    font-weight: bold;
  }
  

  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300&display=swap');



  

  



    
  .subtitleAgenda {
    font-size: 2em;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color:rgba(14, 14, 14, 0.829);
    margin: 60px;
  }
  
  h3 {
    padding-top: 0.25em !important;
  }
  
  .vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
      0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
  }
  
  .vertical-timeline-element-subtitle{
    margin-top: 20px !important;
  }
  
  .vertical-timeline-element-title{
    color: #000000ce;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
  }
  


  .dateAgenda{
    color: rgb(3, 3, 3) !important;
    font-weight: bolder !important;
    z-index: 1 !important;
  }
  
  #description {
    margin: 1.5em 0 2em 0;
    text-align: justify;
    color: #000000ce;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
  }
  
  .button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white;
  }
  
  .workButton {
    background-color: #06d6a0;
  }
  
  .workButton:hover {
    background-color: #0ac593;
  }
  
  .schoolButton {
    background-color: #f9c74f;
  }
  
  .schoolButton:hover {
    background-color: #f3bc3c;
  }
  
  .spacer {
    margin-top: 20px; /* Adjust the desired amount of space */
  }
  .vertical-timeline-element-date{
    margin: 8px;
    top: -10px !important;
  }


.agenda-texto{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
}


  /* Loading gif */

.white-div{
    background-color: rgb(223 223 223);
    height: 600px;
    width: 100%;
}

  .dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
    margin: auto;
  }
  
  .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  
  .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
  }
  
  .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
  }
  
  .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
  }
  
  .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
  }
  
  .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
  }
  
  .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
  }
  
  .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
  }
  
  .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
  }
  
  .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
  }
  
  .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
  }
  
  .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
  }
  
  .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
  }
  
  .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
  }
  
  .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
  }
  
  .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
  }
  
  @keyframes pulse0112 {
    0%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }
  
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
  


/* title underline */

.agenda__heading:before {
    position: absolute;
    content: '';
    border-bottom: 25px solid rgb(211, 223, 100);
    width: 18rem;
    display: block;
    margin: 0 auto;
    position: relative;
    left: 2.5rem;
    top: 5.7rem;
    z-index: -1;
}

.titleAgenda {
    font-size: 5em;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color:rgb(37, 37, 37);
  }

@media (max-width: 626px){
    .titleAgenda{
      font-size: 3em !important;
    }
    .agenda__heading:before{
        border-bottom: 15px solid rgb(211, 223, 100);
        width: 10rem;
        left: 1.3rem;
        top: 3.3rem;
    }
  }
  

  a{
    color: #0b4daf;
  }

  @media only screen and (max-width: 1170px) {
    .vertical-timeline-element-date {
      display: block !important;
      float: none !important;
      color: rgb(0, 0, 0) !important;
      margin-top: 1.5em;
    }
    .vertical-timeline-element-content {
        box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
          0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
        padding: 1em 1em !important;
      }
  }

  @media (max-width: 470px){
    .subtitleAgenda{
        font-size: 1.2em;
        margin: 20px;
        margin-bottom: 40px;
    }
  }