@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300&display=swap');



.vertical-timeline-element-title{
  text-align: center !important;
  font-size: 30px !important;
  margin-bottom: 20px !important;
}

.subtitulo-timeline{
    text-align: center;
    font-size: 1.25rem;
    margin-top: 5px;
    color: #3b3b3bce;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin-bottom: 5px;
}

.linkSim {
  cursor:pointer;
  color:blue;
  text-decoration:underline;
}
.timeline-image-right {
  max-width: 100%;
  height: auto;
}
@media (min-width: 1170px) {
  .timeline-image-right {
      position: absolute;
      width: 100%;
      left: 124%;
      top: 50px;
    }
    .mgbottom{
      margin-bottom: 150px !important;
    }
    .mgbottom2{
      margin-bottom: 200px !important;
    }
}

.timeline-image-left {
  max-width: 100%;
  height: auto;
}

.alignCenter{
  text-align: center;
}
@media (min-width: 1170px) {
  .timeline-image-left {
      position: absolute;
      left:auto;
      right: 124%;
      top: 50px;
    }
}
.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
  display: block !important;
}
.highlighted {
  background-color: rgba(255, 255, 0, 0.432);
  border-radius: 10px;
}

.text-legenda-esquerda{
  font-size: 0.8rem;
}

@media (min-width: 1170px) {
  .text-legenda-esquerda {
      color: rgba(240, 248, 255, 0.705);
      width: 100%;
      bottom: 0% !important;
      height: inherit;
      font-size: 0.8rem;
      top: 55px;
      right: 122%;
  }
}

.text-legenda-direita{
  font-size: 0.8rem;
}

@media (min-width: 1170px) {
  .text-legenda-direita {
      color: rgba(240, 248, 255, 0.705);
      width: 100%;
      bottom: 0% !important;
      height: inherit;
      font-size: 0.8rem;
      top: 55px;
      left: 126%;
  }
}
.headerTimeline{
  width: 100%;
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300&display=swap');






body {
  background: rgb(223 223 223) !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  color: rgb(53, 53, 53) !important;
}



.subtitle {
  font-size: 2em;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color:rgba(37, 37, 37, 0.829);
  margin: 60px;
}

h3 {
  padding-top: 0.25em !important;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.vertical-timeline-element-subtitle{
  margin-top: 20px !important;
}

.vertical-timeline-element-title{
  color: #000000ce;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.date {
  color: rgb(44, 44, 44);
  font-weight: bolder !important;
}

#description {
  margin: 1.5em 0 2em 0;
  text-align: justify;
  color: #000000ce;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.workButton {
  background-color: #06d6a0;
}

.workButton:hover {
  background-color: #0ac593;
}

.schoolButton {
  background-color: #f9c74f;
}

.schoolButton:hover {
  background-color: #f3bc3c;
}

.spacer {
  margin-top: 20px; /* Adjust the desired amount of space */
}
.vertical-timeline-element-date{
  margin: 8px;
  top: -10px !important;
}

.divisoria-invasoes{
  font-size: 3em;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color:rgba(255, 255, 255, 0.829);
  margin: 60px;
  background-color: #db3e3e;
  max-width: 2000px;
  margin: 60px auto; /* Set left and right margin to auto */
}

.vertical-timeline-element-content p{
  font-size: 1.02rem !important;
  font-weight: 500 !important;
}

.color-white{
  color: antiquewhite !important;
}

@media (max-width: 490px){
  .vertical-timeline-element-content{
    padding: 1em 1em !important;
  }
}




@media only screen and (max-width: 1170px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(0, 0, 0) !important;
    margin-top: 1.5em;
  }
  .subtitle{
    font-size: 24px !important;
  }
  .date {
    color: rgb(0, 0, 0) !important;
  }
  .vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
      0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 1em 1em !important;
  }
}

@media (max-width: 626px){
  .title{
    font-size: 3em !important;
  }
  .subtitle{
    font-size: 18px !important;
    margin: 20px;
    margin-bottom: 40px;
  }
  .divisoria-invasoes{
    font-size: 2em;
    padding: 0.5em;
  }
}


/*LOADER GIF*/
.modal1{
  z-index: 10;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.322);
  width: 100vw;
  height: 100vh;
}
.modal2{
  margin-top:50vh;
  z-index: 11;
}

.dot-spinner2 {
  --uib-size: 2.8rem;
  --uib-speed: .9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
  margin: auto;
}

.dot-spinner__dot2 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot2::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot2:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot2:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot2:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot2:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot2:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot2:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot2:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot2:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot2:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot2:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot2:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot2:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot2:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot2:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

